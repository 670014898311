.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
    color: #000;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #007bff;
    background-color: transparent;
    border-color: transparent;
}