.table-wrapper-scrollable {
    height: 650px;
    overflow: auto;
}

.format-text-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-view-height-sm {
    overflow: auto;
    height: 19.80em;
}

.table-view-height-md {
    overflow: auto;
    height: 25em;
}