.form-signin {
    width: 100%;
    max-width: 370px;
    height: 480px;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    position: absolute;
    box-shadow: 0 3px 12px rgba(0, 0, 0, .5);
    background-color: #fff;
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

img.form-logo {
    width: 100%;
    text-align: center;
    padding: 1em 1em 0em 1em;
}

.otp-container {
    justify-content: center;
}

.otp-input {
    width: 1.5em !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom-color: #007bff !important;
}

.otp-input:focus {
    outline: none;
}

#loginAndSignupForm>.nav-tabs .nav-link {
    color: #007bff;
    border-radius: unset;
    border-bottom: 2px solid transparent;
    padding-top: 0.5em;
}

#loginAndSignupForm>.nav-tabs .nav-link.active {
    color: #007bff;
    font-weight: bold;
    border-color: #fff #fff #007bff;
}