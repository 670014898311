/* General */

.custom-height {
    height: 750px;
}

.bg-black {
    background-color: #000;
}

.col-custom-wrapper-12 {
    width: 100%;
}

.col-custom-wrapper-10 {
    width: 80%;
}

.col-custom-wrapper-2 {
    width: 20%;
}

.rounded-custom-top {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.rounded-custom-bottom {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.custom-form-control {
    height: 31px;
    border-color: hsl(0, 0%, 80%) !important;
    font-size: .9rem;
}

.custom-form-control:focus {
    border-width: 1px;
    box-shadow: 0 0 0 2px #2684FF;
    border-right: none;
}

.input-with-icons i {
    position: absolute;
    right: 0;
    padding: .25rem 0.5rem;
}

.btn-dark {
    background-color: #000 !important;
}


/* Headers */

.collapse.navbar-collapse {
    padding-top: 1rem;
    height: 0;
}

ul#nav-header>a.nav-link {
    padding-right: .75em !important;
    padding-left: .75em !important;
    color: #fff !important;
    font-weight: bold;
}

ul#nav-header>a.nav-link.active {
    background-color: #000;
    border-radius: 5px;
    display: inline-block;
}

ul#nav-header>a.nav-link.active:after {
    content: "";
    border: solid #fff;
    border-width: 0 2px 2px 0;
    position: relative;
    display: block;
    height: 0;
    width: 0;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin: auto;
}


/* Filter section */

.filter-content-container {
    position: absolute;
    top: 0;
    right: 0;
}


/* REACT SELECT OVERRIDE STYLES */

.react-select__control {
    font-size: small;
    height: 33px !important;
    min-height: 33px !important;
}

.react-select__menu {
    margin-top: 2px !important;
}

.react-select__option {
    font-size: small !important;
}

.react-select__menu-list {
    max-height: 125px !important;
    position: relative !important;
}

.react-select__loading-indicator {
    color: #0062cc !important;
}

.react-select__indicator.react-select__dropdown-indicator {
    padding: 3px !important;
}

.react-select__indicator.react-select__clear-indicator {
    padding: 0px !important;
}

.react-select__indicator-separator {
    display: none !important;
}

.react-select__value-container {
    font-size: 0.9em !important;
    padding-left: 12px !important;
}


/* REACT DATE PICKER */

.react-datepicker-wrapper {
    width: 100%;
}

/* PRIME COMPONENTS */
.p-calendar,
.p-dropdown {
    width: 100%;
    height: 31px;
}

.p-dropdown-items-wrapper {
    font-size: .9em;
}
.p-dropdown-label.p-inputtext {
    font-size: .9em;
    padding: 6px 12px;
}

.p-inputtext.p-component,
.p-dropdown-filter-container > .p-inputtext {
    height: 31px;
    font-size: .9em;
}
.p-datepicker table th > span,
.p-datepicker table td > span {
    width: 1.75em !important;
    height: 1.75em !important;
    font-size: small !important;
}
